import {extendTheme} from '@chakra-ui/react';

const makeTheme = (globalStyles: Record<string, unknown> = {}) =>
	extendTheme({
		fonts: {
			heading: 'Jost',
			body: 'Inter',
		},
		components: {
			Code: {
				baseStyle: {
					px: 2,
					py: 1,
					borderRadius: 'lg',
				},
			},
			Button: {
				variants: {
					navigation: {
						w: {base: 'full', md: 'auto'},
						p: 0,
						fontWeight: '600',
						color: 'gray.700',
						fontFamily: 'Jost',
						transition: 'all 0.1s',
						borderRadius: '0',
						_hover: {
							borderBottom: '2px solid',
							borderColor: 'blue.500',
						},
					},
				},
			},
		},
		colors: {
			black: '#0e1012',
			gray: {
				'50': '#f9fafb',
				'100': '#f0f2f4',
				'200': '#e5e8ec',
				'300': '#cfd4dc',
				'400': '#a3aebc',
				'500': '#748092',
				'600': '#4d5561',
				'700': '#32373f',
				'800': '#1d2025',
				'900': '#171a1d',
			},
			teal: {
				'50': '#f3fbfb',
				'100': '#cdefee',
				'200': '#a0e0de',
				'300': '#67ceca',
				'400': '#15b4ae',
				'500': '#019994',
				'600': '#017c78',
				'700': '#01615d',
				'800': '#00514e',
				'900': '#004340',
			},
			cyan: {
				'50': '#f5fbfc',
				'100': '#d5eef1',
				'200': '#c2e6eb',
				'300': '#acdde4',
				'400': '#6ac2cf',
				'500': '#49b4c4',
				'600': '#22a4b8',
				'700': '#01889c',
				'800': '#017081',
				'900': '#015664',
			},
			blue: {
				'50': '#f2f7fb',
				'100': '#cee0ef',
				'200': '#abc9e4',
				'300': '#84b0d7',
				'400': '#5e99cb',
				'500': '#3d83c0',
				'600': '#196db5',
				'700': '#015197',
				'800': '#01437c',
				'900': '#013766',
			},
			purple: {
				'50': '#f8f6fc',
				'100': '#e3dbf3',
				'200': '#cec1eb',
				'300': '#af9bdf',
				'400': '#9a80d6',
				'500': '#7f5ecb',
				'600': '#6b45c3',
				'700': '#582dbb',
				'800': '#4414b3',
				'900': '#2e0196',
			},
			pink: {
				'50': '#fcf6f9',
				'100': '#f3dae8',
				'200': '#eabdd5',
				'300': '#dd94bb',
				'400': '#d377a8',
				'500': '#c64f8f',
				'600': '#bc2e7a',
				'700': '#a8015a',
				'800': '#850147',
				'900': '#640136',
			},
			orange: {
				'50': '#fdfaf8',
				'100': '#f6ebe2',
				'200': '#ebd5c1',
				'300': '#dcb592',
				'400': '#ce9767',
				'500': '#c17c3f',
				'600': '#b45f16',
				'700': '#974701',
				'800': '#773801',
				'900': '#622e01',
			},
			yellow: {
				'50': '#fefefd',
				'100': '#fbf9f1',
				'200': '#f2edd8',
				'300': '#e9dfba',
				'400': '#dbcc8f',
				'500': '#c0a63d',
				'600': '#a28301',
				'700': '#7f6601',
				'800': '#5f4c01',
				'900': '#4e3f00',
			},
			green: {
				'50': '#f7fcfa',
				'100': '#d5f1e2',
				'200': '#a7e2c3',
				'300': '#71d19e',
				'400': '#32bd73',
				'500': '#01a54d',
				'600': '#018840',
				'700': '#016a32',
				'800': '#015729',
				'900': '#004822',
			},
			primary: {
				'50': '#eefaf9',
				'100': '#bbe9e8',
				'200': '#7bd4d3',
				'300': '#23b8b5',
				'400': '#01a5a2',
				'500': '#018c89',
				'600': '#017674',
				'700': '#015f5d',
				'800': '#00504f',
				'900': '#003a39',
			},
		},
		styles: {
			global: globalStyles,
		},
	});

export default makeTheme;
